import {connect} from 'react-redux'
import Layout from '@@client/app/Layout'
import Link from 'next/link'
import {getServerConfig} from '@@client/config'
import {getHomepageContent} from '@@client/content/api'
import Markdown from '@@client/components/Markdown'
import SearchAndSlider, {Slide} from '@@client/home/SearchAndSlider'
import NewArrivals from '@@client/home/NewArrivals'
import FeaturedPartTypesSection from '@@client/home/FeaturedPartTypesSection'
import OurStuff from '@@client/home/OurStuff'

const Contact = connect(
    ({config}) => ({config}),
    () => ({}),
)(({config}) => {
    return (
        <>
            <div className='contact t-xs'>
                <div><a className='btn btn--blue--light' href={`tel:${config.contact.phoneFull}`}>{config.contact.phoneHuman}</a></div>
                <div><Link href='/locations'><a className='btn btn--blue--dark'>Store Locations</a></Link></div>
            </div>
            <style jsx>
                {`
                    @import '@css/variables.css';

                    .contact {
                        margin: 0 $page-lr-margin-xs $padding--section--tb;
                        @media(--xs) { margin-bottom: $padding--section--tb--xs; }

                        :global(.btn) {
                            width: 100%;
                        }

                        & > * {
                            margin-bottom: 13px;

                            &:last-child { margin-bottom: 0; }
                        }
                    }
                `}
            </style>
        </>
    )
})

function Index({homepageContent}) {
    const slides = homepageContent.slides || []

    return (
        <Layout>
            <SearchAndSlider>
                {slides.map(slide => (
                    <Slide
                        key={slide.id}
                        imageUrl={slide.backgroundImage.url}
                        alt={slide.backgroundImage.alternativeText}
                        heading={slide.title}
                        subContent={slide.subContent ? (<Markdown>{slide.subContent}</Markdown>) : null}
                    >
                        <Markdown>{slide.content}</Markdown>
                    </Slide>
                ))}
            </SearchAndSlider>

            <div className='gray-bg'>
                <FeaturedPartTypesSection title={homepageContent.categoriesTitle} description={homepageContent.categoriesDescription} />
                <Contact />
                <NewArrivals />
            </div>

            <div className='white-bg'>
                <OurStuff />
            </div>

            <style jsx>
                {`
                    @import '@css/variables.css';

                    .gray-bg {
                        position: relative;
                        background: $c--gray--bg;
                        padding: $padding--section--tb 0;
                        @media(--xs) { padding: $padding--section--tb--xs 0; }

                        &::before {
                            content: '';
                            position: absolute;
                            display: none;
                            width: 100%;
                            height: 19px;
                            top: 0;
                            left: 0;
                            background: linear-gradient(#666C82, $c--gray--bg);
                            opacity: 0.5;

                            @media(--sm) {
                                display: block;
                            }
                        }
                    }

                    .white-bg {
                        @media(--sm) {
                            padding: $padding--section--tb 0;
                            @media(--xs) { padding: $padding--section--tb--xs 0; }
                        }
                    }
                `}
            </style>
        </Layout>
    )
}

export default Index

export async function getServerSideProps(ctx) {
    const config = getServerConfig()

    const homepageContentResult = await getHomepageContent(config.content.apiUrl)

    return {
        props: {
            homepageContent: homepageContentResult.success ? homepageContentResult.entity : {},
        },
    }
}
